body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
  width: 100%;
}

body {
  background-image: url(../assets/images/background.png);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  margin: 0px;
  padding: 0px;
}

a {
  margin: 0px;
  padding: 0px;
}

.iconePasta {
  margin-bottom: -5px;
}

.logo {
  margin-bottom: 62px;
}

.item1 {
  margin-top: 62px;
}

.bg-icone {
  display: flex;
  position: relative;
  width: 38px;
  height: 38px;
  background-color: #162a35;
  border-radius: 30px;
}

.headerDash {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1265.32px;
  height: 50px;
}

.titleDash {
  margin: 51px 0px 0px 33px;
  font-size: 25px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  line-height: 33.25px;
  color: #3f4254;
}

.textDash {
  margin: 51px 0px 0px 0px;
  font-size: 25px;
  font-family: "Noto Sans", sans-serif;
  justify-self: flex-end;
  font-weight: 700;
  line-height: 33.25px;
  color: #3f4254;
}

.firstCard {
  margin-left: 21px;
}

.infosgerais {
  display: flex;
  justify-content: space-around;
  width: 1265.32px;
}

.titleh2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.titleh2 h2 {
  margin-bottom: 0px;
}

.conteudo-cadastro {
  width: 1266px;
  height: 700px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 16px;
  margin-top: 16px;
  border-radius: 8.49px;
  margin-bottom: 50px;
  box-shadow: 0px 17px 8.486px rgba(239, 241, 249, 0.75);
}

.titleCadastroeventos {
  align-self: center;
  margin-bottom: auto;
  margin-top: 59px;
  height: 48px;
  padding: 0px;
}

.inserts {
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  justify-content: space-between;
  margin-left: 40px;
  margin-bottom: auto;
  margin-top: -20px;
}

.inserts p {
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 15px;
}

.inputCadastro1 {
  width: 402.52px;
  height: 54.36px;
  padding-left: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: none;
}

.inputCadastro3 {
  width: 319px;
  height: 54.36px;
  padding-left: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: none;
}
.addimagem {
  display: flex;
  align-items: center;
}

.botaoADD {
  width: 35px;
  height: 35px;
  background-color: #162a35;
  background-image: url(../assets/images/Mais.svg);
  background-position: center;
  border-radius: 25px;
  margin-left: 31px;
  padding: 0px;
}

.botoes {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 62px;
}

.btn-cadastro-eventos {
  background-color: #162a35;
  color: #fff;
  width: 148.5px;
  height: 51.3px;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
  border-radius: 11px;
  transition: background-color 0.2s;
  cursor: pointer;
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.btn-cadastro-eventos:hover {
  background-color: #0071a13d;
  color: #000;
}

.btn-cancelar {
  background-color: #fff;
  color: #162a35;
  width: 148.5px;
  height: 51.3px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 11px;
  transition: background-color 0.2s;
  cursor: pointer;
  margin-left: 29px;
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.btn-cancelar:hover {
  background-color: #162a35;
  color: #fff;
}

/*ESTILIZAÇÃO DA PAGE DE CADASTRO DE EVENTOS*/
.Container-geral {
  width: 95%;
  height: 817px;
  background-color: #fff;
  margin-left: 46px;
  border-radius: 20px;
  margin-top: 16px;
}

.infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.titulo-page {
  margin-left: 40px;
}
.titulo-page h1 {
  font-size: 19px;
  margin-bottom: 0px;
}

.titulo-page span {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
.titulo-modal {
  text-align: center !important;
}

.inputPesquisa {
  width: 541px;
  height: 50px;
  display: inline-block;
  background-image: url(../assets/images/iconelupa.svg);
  background-repeat: no-repeat;
  background-position: center left 210px;
  border-radius: 10px;
  background-color: rgba(227, 227, 227, 0.4);
  border: none;
  margin-top: 16px;
  margin-left: auto;
  padding-left: 10px;
}

.inputPesquisa::placeholder {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.inputPesquisa:focus {
  background-image: none;
}

.btn-cadastro {
  width: 183px;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 18px;
  margin-top: 16px;
  cursor: pointer;
  margin-right: 33px;
  margin-left: 35px;
}

.btn-cadastro:hover {
  filter: contrast(0.5);
}

/*ESTILIZAÇÃO DA PÁGINA DE CADASTRAR EVENTO*/

.posicionar-page-cadastrar {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 402px 402px;
  gap: 10px 100px;
  margin-top: 45px;
}

.inputs {
  width: 100%;
  height: 54.36px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0px 20px;
  background-color: #eff1f9cc;
}

.input-small {
  width: 70%;
  height: 54.36px;
  border-radius: 10px;
  border: none;
  padding: 0px 20px;
  background-color: #eff1f9cc;
  border: 1px solid #ccc;
}

.conjunto-img-add {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.add-img {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  border: #162a35;
  margin-left: 35px;
  background-color: #162a35;
  cursor: pointer;
  background-image: url(../assets/images/Mais.svg);
  background-position: top;
}

.add-img:active {
  border: solid 2px #fff;
}

.edit-img {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  border: #162a35;
  margin-left: 35px;
  background-color: #162a35;
  cursor: pointer;
  /* background-image: url(../assets/images/editIcon.svg); */
  background-position: top;
}

.edit-img:active {
  border: solid 2px #fff;
}
.botao-edit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
  left: 60%;
}

.botao-edit-categorias {
  position: relative;
  grid-row: 3;
  left: 75%;
}

.conjunto-img-add2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-row: 2;
}

/*Estilização do modal de edicao da page categorias*/

.botao-edit2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  left: 40%;
  gap: 0px 30px;
}

.botao-edit2 button {
  background-color: #162a35;
  color: #fff;
  width: 50%;
  height: 51.3px;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
  border-radius: 11px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.botao-edit2 button:hover {
  background-color: #0071a13d;
}

/*Alterando o modal edit*/

.conjunto-img-add3 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-row: 2;
}

.botao-edit3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  position: relative;
  left: 45%;
  margin-top: 20%;
}

.botao-edit3 button {
  background-color: #162a35;
  color: #fff;
  width: 50%;
  height: 51.3px;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
  border-radius: 11px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.botao-edit3 button:hover {
  background-color: #0071a13d;
}
